.smart-fleet-management-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.smart-fleet-management-navbar {
  width: 100%;
  height: 88px;
  display: flex;
  z-index: 100;
  position: fixed;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  justify-content: center;
  background-color: white;
}
.smart-fleet-management-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.smart-fleet-management-image1 {
  height: 3rem;
}
.smart-fleet-management-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.smart-fleet-management-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.smart-fleet-management-link11 {
  text-decoration: none;
}
.smart-fleet-management-burger-menu {
  display: none;
}
.smart-fleet-management-icon1 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.smart-fleet-management-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.smart-fleet-management-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.smart-fleet-management-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.smart-fleet-management-logo {
  height: 3rem;
}
.smart-fleet-management-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.smart-fleet-management-icon3 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.smart-fleet-management-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.smart-fleet-management-link12 {
  text-decoration: none;
}
.smart-fleet-management-main-content {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/desktop%20-%2020-1500w.png");
}
.smart-fleet-management-frame19272 {
  gap: 10px;
  width: 1280px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  padding-top: 162px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 120px;
}
.smart-fleet-management-frame19268 {
  gap: 80px;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 2;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.smart-fleet-management-frame19303 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.smart-fleet-management-navlink {
  display: contents;
}
.smart-fleet-management-frame19229 {
  gap: 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.smart-fleet-management-arrowleft {
  width: 24px;
  height: 24px;
}
.smart-fleet-management-text10 {
  color: var(--dl-color-orange-800main);
  height: auto;
  text-align: left;
  line-height: 27px;
}
.smart-fleet-management-frame19260 {
  gap: 24px;
  width: 730px;
  height: 300px;
  display: flex;
  padding: 32px 32px 48px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-orange-800main);
}
.smart-fleet-management-frame19285 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
.smart-fleet-management-text12 {
  color: var(--dl-color-white-white);
  height: auto;
  text-align: left;
  line-height: 24px;
}
.smart-fleet-management-trucktick1 {
  width: 52px;
  height: 52px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.smart-fleet-management-vuesaxlineartrucktick {
  top: 0px;
  left: 0px;
  width: 52px;
  height: 52px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smart-fleet-management-trucktick2 {
  top: 0px;
  left: 0px;
  width: 52px;
  height: 52px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smart-fleet-management-vector10 {
  top: 4.333333492279053px;
  left: 4.333333492279053px;
  width: 28px;
  height: 26px;
  position: absolute;
}
.smart-fleet-management-vector11 {
  top: 10.833333015441895px;
  left: 4.333333492279053px;
  width: 43px;
  height: 32px;
  position: absolute;
}
.smart-fleet-management-vector12 {
  top: 39px;
  left: 13px;
  width: 9px;
  height: 9px;
  position: absolute;
}
.smart-fleet-management-vector13 {
  top: 39px;
  left: 30.33333396911621px;
  width: 9px;
  height: 9px;
  position: absolute;
}
.smart-fleet-management-vector14 {
  top: 19.5px;
  left: 39px;
  width: 9px;
  height: 11px;
  position: absolute;
}
.smart-fleet-management-group {
  top: 2.1650798320770264px;
  left: 2.164154052734375px;
  width: 17.338788986206055px;
  height: 17.337539672851562px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smart-fleet-management-vector15 {
  top: 0px;
  left: 0px;
  width: 17px;
  height: 17px;
  position: absolute;
}
.smart-fleet-management-vector16 {
  top: 6.4365234375px;
  left: 5.2891845703125px;
  width: 7px;
  height: 4px;
  position: absolute;
}
.smart-fleet-management-text14 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.smart-fleet-management-frame19286 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.smart-fleet-management-text16 {
  color: var(--dl-color-neutral-500sub);
  width: 800px;
  height: auto;
  text-align: left;
  line-height: 27px;
}
.smart-fleet-management-feature {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: var(--dl-color-neutral-900main);
}
.smart-fleet-management-frame19290 {
  gap: 64px;
  width: 1280px;
  display: flex;
  padding: 100px 0 160px;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.smart-fleet-management-text18 {
  color: var(--dl-color-orange-800main);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.smart-fleet-management-frame61 {
  gap: 40px;
  width: 600px;
  display: flex;
  padding: 32px 24px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: var(--dl-color-neutral-800);
}
.smart-fleet-management-shieldtick1 {
  width: 44px;
  height: 44px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.smart-fleet-management-vuesaxlinearshieldtick {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smart-fleet-management-shieldtick2 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smart-fleet-management-vector17 {
  top: 3.662083387374878px;
  left: 6.251667022705078px;
  width: 32px;
  height: 37px;
  position: absolute;
}
.smart-fleet-management-vector18 {
  top: 16.829999923706055px;
  left: 16.59166717529297px;
  width: 11px;
  height: 8px;
  position: absolute;
}
.smart-fleet-management-frame78 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smart-fleet-management-text20 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.smart-fleet-management-text22 {
  color: var(--dl-color-neutral-200);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.smart-fleet-management-rectangle7424 {
  top: 0px;
  right: -2px;
  width: 100%;
  height: 460px;
  position: absolute;
}
.smart-fleet-management-footer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: #EE7127;
}
.smart-fleet-management-frame105 {
  gap: 0px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.20000000298023224);
  border-style: solid;
  border-width: 1px 0 0;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-orange-800main);
}
.smart-fleet-management-frame1041 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.smart-fleet-management-frame1061 {
  gap: 40px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.smart-fleet-management-frame111 {
  gap: 100px;
  width: 398px;
  display: flex;
  padding: 40px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
}
.smart-fleet-management-primarylogoasaplainbackground2 {
  width: 52px;
  height: 52px;
}
.smart-fleet-management-frame109 {
  gap: 12px;
  width: 246px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.smart-fleet-management-text24 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.smart-fleet-management-text26 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.smart-fleet-management-text28 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.smart-fleet-management-text30 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.smart-fleet-management-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.smart-fleet-management-rectangle11 {
  width: 1px;
  height: auto;
  align-self: stretch;
}
.smart-fleet-management-frame110 {
  gap: 12px;
  width: 360px;
  height: 376px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;
  padding-bottom: 24px;
}
.smart-fleet-management-text32 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smart-fleet-management-frame116 {
  gap: 24px;
  width: 100%;
  height: 263px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smart-fleet-management-frame1141 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smart-fleet-management-text34 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.smart-fleet-management-text36 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smart-fleet-management-frame1171 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.smart-fleet-management-vuesaxlinearcall1 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.smart-fleet-management-vuesaxlinearcall2 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smart-fleet-management-call1 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smart-fleet-management-call2 {
  top: 1.3333333730697632px;
  left: 1.3333333730697632px;
  width: 13.313333511352539px;
  height: 13.333333969116211px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smart-fleet-management-vector19 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 13px;
  position: absolute;
}
.smart-fleet-management-text37 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smart-fleet-management-rectangle13 {
  width: auto;
  height: 1px;
  align-self: stretch;
}
.smart-fleet-management-frame115 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smart-fleet-management-text39 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.smart-fleet-management-text41 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smart-fleet-management-frame1172 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.smart-fleet-management-vuesaxlinearcall3 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.smart-fleet-management-vuesaxlinearcall4 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smart-fleet-management-call3 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smart-fleet-management-call4 {
  top: 1.3333333730697632px;
  left: 1.3333333730697632px;
  width: 13.313333511352539px;
  height: 13.333333969116211px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smart-fleet-management-vector20 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 13px;
  position: absolute;
}
.smart-fleet-management-text43 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smart-fleet-management-rectangle12 {
  width: 1px;
  height: auto;
  align-self: stretch;
}
.smart-fleet-management-frame113 {
  gap: 12px;
  width: 360px;
  height: 376px;
  display: flex;
  padding: 40px 0;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.smart-fleet-management-text45 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smart-fleet-management-frame1142 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smart-fleet-management-text47 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.smart-fleet-management-frame1062 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.20000000298023224);
  border-style: solid;
  border-width: 1px 0 0;
  flex-direction: column;
  background-color: var(--dl-color-orange-800main);
}
.smart-fleet-management-frame1042 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smart-fleet-management-frame108 {
  width: 1280px;
  display: flex;
  padding: 20px 0;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.smart-fleet-management-text49 {
  color: var(--dl-color-white-white);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smart-fleet-management-frame107 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.smart-fleet-management-uillinkedinalt {
  width: 20px;
  height: 20px;
}
.smart-fleet-management-facebook {
  width: 20px;
  height: 20px;
}
.smart-fleet-management-instagram {
  width: 20px;
  height: 20px;
}
@media(max-width: 1274px) {
  .smart-fleet-management-frame19272 {
    width: 100%;
    padding-top: 200px;
  }
  .smart-fleet-management-frame19268 {
    width: 768px;
  }
  .smart-fleet-management-feature {
    width: 100%;
  }
  .smart-fleet-management-frame19290 {
    width: 768px;
  }
  .smart-fleet-management-frame105 {
    width: 769px;
    height: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .smart-fleet-management-frame1041 {
    width: 769px;
    align-self: center;
    justify-content: center;
  }
  .smart-fleet-management-frame1061 {
    gap: 16px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .smart-fleet-management-frame111 {
    gap: 24px;
    width: auto;
    align-self: flex-start;
    padding-top: 24px;
    padding-left: 16px;
    padding-bottom: 24px;
  }
  .smart-fleet-management-frame109 {
    width: auto;
    margin-right: 0px;
  }
  .smart-fleet-management-frame110 {
    width: 277px;
    height: 419px;
    padding: 24px;
  }
  .smart-fleet-management-frame113 {
    width: 218px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .smart-fleet-management-frame1142 {
    width: 100%;
  }
  .smart-fleet-management-text47 {
    width: 100%;
    font-size: 24px;
  }
  .smart-fleet-management-frame1062 {
    width: 768px;
    align-self: center;
  }
  .smart-fleet-management-frame108 {
    width: 100%;
    align-self: center;
  }
}
@media(max-width: 768px) {
  .smart-fleet-management-desktop-menu {
    display: none;
  }
  .smart-fleet-management-burger-menu {
    display: flex;
  }
  .smart-fleet-management-frame105 {
    width: 100%;
  }
  .smart-fleet-management-frame1041 {
    width: 100%;
  }
  .smart-fleet-management-frame1061 {
    gap: 16px;
    width: 390PX;
    flex-direction: column;
  }
  .smart-fleet-management-frame111 {
    width: 363px;
    align-self: center;
    margin-right: 0px;
  }
  .smart-fleet-management-text36 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 21px;
    text-decoration: none;
  }
  .smart-fleet-management-frame113 {
    height: auto;
    align-self: center;
  }
  .smart-fleet-management-frame1062 {
    width: 100%;
    height: 97px;
  }
  .smart-fleet-management-frame1042 {
    width: 100%;
    align-self: center;
  }
  .smart-fleet-management-frame108 {
    gap: 24px;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
  }
  .smart-fleet-management-text49 {
    color: var(--dl-color-white-white);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 21px;
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .smart-fleet-management-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .smart-fleet-management-desktop-menu {
    display: none;
  }
  .smart-fleet-management-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .smart-fleet-management-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .smart-fleet-management-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
