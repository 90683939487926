.know-your-costumer-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.know-your-costumer-navbar {
  width: 100%;
  height: 88px;
  display: flex;
  z-index: 100;
  position: fixed;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  justify-content: center;
  background-color: white;
}
.know-your-costumer-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.know-your-costumer-image1 {
  height: 3rem;
}
.know-your-costumer-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.know-your-costumer-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.know-your-costumer-link11 {
  text-decoration: none;
}
.know-your-costumer-burger-menu {
  display: none;
}
.know-your-costumer-icon1 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.know-your-costumer-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.know-your-costumer-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.know-your-costumer-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.know-your-costumer-logo {
  height: 3rem;
}
.know-your-costumer-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.know-your-costumer-icon3 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.know-your-costumer-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.know-your-costumer-link12 {
  text-decoration: none;
}
.know-your-costumer-main-content {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/desktop%20-%2020-1500w.png");
}
.know-your-costumer-frame19272 {
  gap: 10px;
  width: 1280px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  padding-top: 162px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 120px;
}
.know-your-costumer-frame19268 {
  gap: 80px;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 2;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.know-your-costumer-frame19306 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.know-your-costumer-navlink {
  display: contents;
}
.know-your-costumer-frame19229 {
  gap: 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.know-your-costumer-arrowleft {
  width: 24px;
  height: 24px;
}
.know-your-costumer-text10 {
  color: var(--dl-color-orange-800main);
  height: auto;
  text-align: left;
  line-height: 27px;
}
.know-your-costumer-frame19260 {
  gap: 24px;
  width: 730px;
  height: 300px;
  display: flex;
  padding: 32px 32px 48px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-orange-800main);
}
.know-your-costumer-frame19285 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
.know-your-costumer-text12 {
  color: var(--dl-color-white-white);
  height: auto;
  text-align: left;
  line-height: 24px;
}
.know-your-costumer-user2 {
  width: 52px;
  height: 52px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.know-your-costumer-vuesaxlinearprofile2user {
  top: 0px;
  left: 0px;
  width: 52px;
  height: 52px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.know-your-costumer-profile2user {
  top: 0px;
  left: 0px;
  width: 52px;
  height: 52px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.know-your-costumer-vector10 {
  top: 4.333333492279053px;
  left: 9.880000114440918px;
  width: 19px;
  height: 19px;
  position: absolute;
}
.know-your-costumer-vector11 {
  top: 8.666666984558105px;
  left: 35.27333450317383px;
  width: 8px;
  height: 15px;
  position: absolute;
}
.know-your-costumer-vector12 {
  top: 28.57291603088379px;
  left: 5.0808329582214355px;
  width: 30px;
  height: 19px;
  position: absolute;
}
.know-your-costumer-vector13 {
  top: 30.33333396911621px;
  left: 39.73666763305664px;
  width: 7px;
  height: 13px;
  position: absolute;
}
.know-your-costumer-text14 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.know-your-costumer-frame19286 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.know-your-costumer-text16 {
  color: var(--dl-color-neutral-500sub);
  width: 800px;
  height: auto;
  text-align: left;
  line-height: 27px;
}
.know-your-costumer-importance-for-business {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: var(--dl-color-neutral-900main);
}
.know-your-costumer-frame19290 {
  gap: 64px;
  width: 1280px;
  display: flex;
  padding: 100px 0 160px;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.know-your-costumer-text18 {
  color: var(--dl-color-orange-800main);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.know-your-costumer-frame19238 {
  gap: 40px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.know-your-costumer-frame61 {
  gap: 40px;
  width: 620px;
  display: flex;
  padding: 32px 24px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-neutral-800);
}
.know-your-costumer-shieldtick1 {
  width: 44px;
  height: 44px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.know-your-costumer-vuesaxlinearshieldtick {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.know-your-costumer-shieldtick2 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.know-your-costumer-vector14 {
  top: 3.662083387374878px;
  left: 6.251667022705078px;
  width: 32px;
  height: 37px;
  position: absolute;
}
.know-your-costumer-vector15 {
  top: 16.829999923706055px;
  left: 16.59166717529297px;
  width: 11px;
  height: 8px;
  position: absolute;
}
.know-your-costumer-frame781 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.know-your-costumer-text20 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.know-your-costumer-text22 {
  color: var(--dl-color-neutral-200);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.know-your-costumer-frame62 {
  gap: 40px;
  width: 620px;
  display: flex;
  padding: 32px 24px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-neutral-800);
}
.know-your-costumer-slidersquare {
  width: 44px;
  height: 44px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.know-your-costumer-vuesaxlinearsetting3 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.know-your-costumer-setting3 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.know-your-costumer-vector16 {
  top: 3.6666667461395264px;
  left: 3.6666667461395264px;
  width: 37px;
  height: 37px;
  position: absolute;
}
.know-your-costumer-vector17 {
  top: 26.766666412353516px;
  left: 28.545000076293945px;
  width: 0px;
  height: 7px;
  position: absolute;
}
.know-your-costumer-vector18 {
  top: 10.083333015441895px;
  left: 28.545000076293945px;
  width: 0px;
  height: 4px;
  position: absolute;
}
.know-your-costumer-vector19 {
  top: 13.658332824707031px;
  left: 23.77833366394043px;
  width: 10px;
  height: 10px;
  position: absolute;
}
.know-your-costumer-vector20 {
  top: 30.341665267944336px;
  left: 15.455000877380371px;
  width: 0px;
  height: 4px;
  position: absolute;
}
.know-your-costumer-vector21 {
  top: 10.083333015441895px;
  left: 15.455000877380371px;
  width: 0px;
  height: 7px;
  position: absolute;
}
.know-your-costumer-vector22 {
  top: 20.808334350585938px;
  left: 10.688333511352539px;
  width: 10px;
  height: 10px;
  position: absolute;
}
.know-your-costumer-frame782 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.know-your-costumer-text24 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.know-your-costumer-text26 {
  color: var(--dl-color-neutral-200);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.know-your-costumer-rectangle7424 {
  top: 0px;
  right: -2px;
  width: 100%;
  height: 460px;
  position: absolute;
}
.know-your-costumer-footer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: #EE7127;
}
.know-your-costumer-frame105 {
  gap: 0px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.20000000298023224);
  border-style: solid;
  border-width: 1px 0 0;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-orange-800main);
}
.know-your-costumer-frame1041 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.know-your-costumer-frame1061 {
  gap: 40px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.know-your-costumer-frame111 {
  gap: 100px;
  width: 398px;
  display: flex;
  padding: 40px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
}
.know-your-costumer-primarylogoasaplainbackground2 {
  width: 52px;
  height: 52px;
}
.know-your-costumer-frame109 {
  gap: 12px;
  width: 246px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.know-your-costumer-text28 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.know-your-costumer-text30 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.know-your-costumer-text32 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.know-your-costumer-text34 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.know-your-costumer-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.know-your-costumer-rectangle11 {
  width: 1px;
  height: auto;
  align-self: stretch;
}
.know-your-costumer-frame110 {
  gap: 12px;
  width: 360px;
  height: 376px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;
  padding-bottom: 24px;
}
.know-your-costumer-text36 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.know-your-costumer-frame116 {
  gap: 24px;
  width: 100%;
  height: 263px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.know-your-costumer-frame1141 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.know-your-costumer-text38 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.know-your-costumer-text40 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.know-your-costumer-frame1171 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.know-your-costumer-vuesaxlinearcall1 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.know-your-costumer-vuesaxlinearcall2 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.know-your-costumer-call1 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.know-your-costumer-call2 {
  top: 1.3333333730697632px;
  left: 1.3333333730697632px;
  width: 13.313333511352539px;
  height: 13.333333969116211px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.know-your-costumer-vector23 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 13px;
  position: absolute;
}
.know-your-costumer-text41 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.know-your-costumer-rectangle13 {
  width: auto;
  height: 1px;
  align-self: stretch;
}
.know-your-costumer-frame115 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.know-your-costumer-text43 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.know-your-costumer-text45 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.know-your-costumer-frame1172 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.know-your-costumer-vuesaxlinearcall3 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.know-your-costumer-vuesaxlinearcall4 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.know-your-costumer-call3 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.know-your-costumer-call4 {
  top: 1.3333333730697632px;
  left: 1.3333333730697632px;
  width: 13.313333511352539px;
  height: 13.333333969116211px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.know-your-costumer-vector24 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 13px;
  position: absolute;
}
.know-your-costumer-text47 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.know-your-costumer-rectangle12 {
  width: 1px;
  height: auto;
  align-self: stretch;
}
.know-your-costumer-frame113 {
  gap: 12px;
  width: 360px;
  height: 376px;
  display: flex;
  padding: 40px 0;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.know-your-costumer-text49 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.know-your-costumer-frame1142 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.know-your-costumer-text51 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.know-your-costumer-frame1062 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.20000000298023224);
  border-style: solid;
  border-width: 1px 0 0;
  flex-direction: column;
  background-color: var(--dl-color-orange-800main);
}
.know-your-costumer-frame1042 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.know-your-costumer-frame108 {
  width: 1280px;
  display: flex;
  padding: 20px 0;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.know-your-costumer-text53 {
  color: var(--dl-color-white-white);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.know-your-costumer-frame107 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.know-your-costumer-uillinkedinalt {
  width: 20px;
  height: 20px;
}
.know-your-costumer-facebook {
  width: 20px;
  height: 20px;
}
.know-your-costumer-instagram {
  width: 20px;
  height: 20px;
}
@media(max-width: 1274px) {
  .know-your-costumer-frame19272 {
    width: 100%;
    padding-top: 200px;
  }
  .know-your-costumer-frame19268 {
    width: 768px;
  }
  .know-your-costumer-importance-for-business {
    width: 100%;
  }
  .know-your-costumer-frame19290 {
    width: 768px;
    align-self: center;
  }
  .know-your-costumer-text18 {
    align-self: center;
  }
  .know-your-costumer-frame19238 {
    width: auto;
    align-items: center;
    flex-direction: column;
  }
  .know-your-costumer-frame61 {
    width: auto;
  }
  .know-your-costumer-frame62 {
    width: 100%;
  }
  .know-your-costumer-frame105 {
    width: 769px;
    height: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .know-your-costumer-frame1041 {
    width: 769px;
    align-self: center;
    justify-content: center;
  }
  .know-your-costumer-frame1061 {
    gap: 16px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .know-your-costumer-frame111 {
    gap: 24px;
    width: auto;
    align-self: flex-start;
    padding-top: 24px;
    padding-left: 16px;
    padding-bottom: 24px;
  }
  .know-your-costumer-frame109 {
    width: auto;
    margin-right: 0px;
  }
  .know-your-costumer-frame110 {
    width: 277px;
    height: 419px;
    padding: 24px;
  }
  .know-your-costumer-frame113 {
    width: 218px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .know-your-costumer-frame1142 {
    width: 100%;
  }
  .know-your-costumer-text51 {
    width: 100%;
    font-size: 24px;
  }
  .know-your-costumer-frame1062 {
    width: 768px;
    align-self: center;
  }
  .know-your-costumer-frame108 {
    width: 100%;
    align-self: center;
  }
}
@media(max-width: 768px) {
  .know-your-costumer-desktop-menu {
    display: none;
  }
  .know-your-costumer-burger-menu {
    display: flex;
  }
  .know-your-costumer-frame19268 {
    width: 390px;
  }
  .know-your-costumer-frame19260 {
    width: 100%;
  }
  .know-your-costumer-frame19290 {
    width: 390px;
    padding-bottom: 80px;
  }
  .know-your-costumer-frame105 {
    width: 100%;
  }
  .know-your-costumer-frame1041 {
    width: 100%;
  }
  .know-your-costumer-frame1061 {
    gap: 16px;
    width: 390PX;
    flex-direction: column;
  }
  .know-your-costumer-frame111 {
    width: 363px;
    align-self: center;
    margin-right: 0px;
  }
  .know-your-costumer-text40 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 21px;
    text-decoration: none;
  }
  .know-your-costumer-frame113 {
    height: auto;
    align-self: center;
  }
  .know-your-costumer-frame1062 {
    width: 100%;
    height: 97px;
  }
  .know-your-costumer-frame1042 {
    width: 100%;
    align-self: center;
  }
  .know-your-costumer-frame108 {
    gap: 24px;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
  }
  .know-your-costumer-text53 {
    color: var(--dl-color-white-white);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 21px;
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .know-your-costumer-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .know-your-costumer-desktop-menu {
    display: none;
  }
  .know-your-costumer-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .know-your-costumer-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .know-your-costumer-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
