.enterprise-ip-camera-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.enterprise-ip-camera-main-content {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/desktop%20-%2020-1500w.png");
}
.enterprise-ip-camera-frame19272 {
  gap: 10px;
  width: 1440px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  padding-top: 162px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 120px;
}
.enterprise-ip-camera-frame19268 {
  gap: 80px;
  width: 1280px;
  height: auto;
  display: flex;
  z-index: 2;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.enterprise-ip-camera-frame19305 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.enterprise-ip-camera-navlink {
  display: contents;
}
.enterprise-ip-camera-frame19229 {
  gap: 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.enterprise-ip-camera-arrowleft {
  width: 24px;
  height: 24px;
}
.enterprise-ip-camera-text10 {
  color: var(--dl-color-orange-800main);
  height: auto;
  text-align: left;
  line-height: 27px;
}
.enterprise-ip-camera-frame19260 {
  gap: 24px;
  width: 730px;
  height: 300px;
  display: flex;
  padding: 32px 32px 48px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-orange-800main);
}
.enterprise-ip-camera-frame19285 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
.enterprise-ip-camera-text12 {
  color: var(--dl-color-white-white);
  height: auto;
  text-align: left;
  line-height: 24px;
}
.enterprise-ip-camera-webcam {
  width: 52px;
  height: 52px;
}
.enterprise-ip-camera-text14 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.enterprise-ip-camera-frame19238 {
  gap: 40px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.enterprise-ip-camera-frame61 {
  gap: 40px;
  display: flex;
  padding: 32px 24px;
  flex-grow: 1;
  align-self: stretch;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-white-white);
}
.enterprise-ip-camera-frame19300 {
  gap: 10px;
  display: flex;
  padding: 40px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.enterprise-ip-camera-rectangle74271 {
  width: auto;
  height: 272px;
  flex-grow: 1;
}
.enterprise-ip-camera-frame781 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.enterprise-ip-camera-text16 {
  color: var(--dl-color-orange-800main);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: normal;
}
.enterprise-ip-camera-text18 {
  color: var(--dl-color-neutral-500sub);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 27px;
}
.enterprise-ip-camera-frame62 {
  gap: 40px;
  display: flex;
  padding: 32px 24px;
  flex-grow: 1;
  align-self: stretch;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-white-white);
}
.enterprise-ip-camera-frame19301 {
  gap: 10px;
  display: flex;
  padding: 40px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.enterprise-ip-camera-rectangle74272 {
  width: auto;
  height: 272px;
  flex-grow: 1;
}
.enterprise-ip-camera-frame782 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.enterprise-ip-camera-text20 {
  color: var(--dl-color-orange-800main);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: normal;
}
.enterprise-ip-camera-text22 {
  color: var(--dl-color-neutral-500sub);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 27px;
}
.enterprise-ip-camera-frame63 {
  gap: 40px;
  display: flex;
  padding: 32px 24px;
  flex-grow: 1;
  align-self: stretch;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-white-white);
}
.enterprise-ip-camera-frame19302 {
  gap: 10px;
  display: flex;
  padding: 40px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.enterprise-ip-camera-rectangle74273 {
  width: auto;
  height: 272px;
  flex-grow: 1;
}
.enterprise-ip-camera-frame783 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.enterprise-ip-camera-text24 {
  color: var(--dl-color-orange-800main);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: normal;
}
.enterprise-ip-camera-text26 {
  color: var(--dl-color-neutral-500sub);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 27px;
}
.enterprise-ip-camera-navbar {
  width: 100%;
  height: 88px;
  display: flex;
  z-index: 100;
  position: fixed;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  justify-content: center;
  background-color: white;
}
.enterprise-ip-camera-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.enterprise-ip-camera-image1 {
  height: 3rem;
}
.enterprise-ip-camera-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.enterprise-ip-camera-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.enterprise-ip-camera-link11 {
  text-decoration: none;
}
.enterprise-ip-camera-burger-menu {
  display: none;
}
.enterprise-ip-camera-icon1 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.enterprise-ip-camera-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.enterprise-ip-camera-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.enterprise-ip-camera-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.enterprise-ip-camera-logo {
  height: 3rem;
}
.enterprise-ip-camera-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.enterprise-ip-camera-icon3 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.enterprise-ip-camera-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.enterprise-ip-camera-link12 {
  text-decoration: none;
}
.enterprise-ip-camera-rectangle7424 {
  top: 0px;
  right: -2px;
  width: 100%;
  height: 460px;
  position: absolute;
}
.enterprise-ip-camera-footer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: #EE7127;
}
.enterprise-ip-camera-frame105 {
  gap: 0px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.20000000298023224);
  border-style: solid;
  border-width: 1px 0 0;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-orange-800main);
}
.enterprise-ip-camera-frame1041 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.enterprise-ip-camera-frame1061 {
  gap: 40px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.enterprise-ip-camera-frame111 {
  gap: 100px;
  width: 398px;
  display: flex;
  padding: 40px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
}
.enterprise-ip-camera-primarylogoasaplainbackground2 {
  width: 52px;
  height: 52px;
}
.enterprise-ip-camera-frame109 {
  gap: 12px;
  width: 246px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.enterprise-ip-camera-text28 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.enterprise-ip-camera-text30 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.enterprise-ip-camera-text32 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.enterprise-ip-camera-text34 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.enterprise-ip-camera-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.enterprise-ip-camera-rectangle11 {
  width: 1px;
  height: auto;
  align-self: stretch;
}
.enterprise-ip-camera-frame110 {
  gap: 12px;
  width: 360px;
  height: 376px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;
  padding-bottom: 24px;
}
.enterprise-ip-camera-text36 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.enterprise-ip-camera-frame116 {
  gap: 24px;
  width: 100%;
  height: 263px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.enterprise-ip-camera-frame1141 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.enterprise-ip-camera-text38 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.enterprise-ip-camera-text40 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.enterprise-ip-camera-frame1171 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.enterprise-ip-camera-vuesaxlinearcall1 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.enterprise-ip-camera-vuesaxlinearcall2 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.enterprise-ip-camera-call1 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.enterprise-ip-camera-call2 {
  top: 1.3333333730697632px;
  left: 1.3333333730697632px;
  width: 13.313333511352539px;
  height: 13.333333969116211px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.enterprise-ip-camera-vector1 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 13px;
  position: absolute;
}
.enterprise-ip-camera-text41 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.enterprise-ip-camera-rectangle13 {
  width: auto;
  height: 1px;
  align-self: stretch;
}
.enterprise-ip-camera-frame115 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.enterprise-ip-camera-text43 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.enterprise-ip-camera-text45 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.enterprise-ip-camera-frame1172 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.enterprise-ip-camera-vuesaxlinearcall3 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.enterprise-ip-camera-vuesaxlinearcall4 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.enterprise-ip-camera-call3 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.enterprise-ip-camera-call4 {
  top: 1.3333333730697632px;
  left: 1.3333333730697632px;
  width: 13.313333511352539px;
  height: 13.333333969116211px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.enterprise-ip-camera-vector2 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 13px;
  position: absolute;
}
.enterprise-ip-camera-text47 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.enterprise-ip-camera-rectangle12 {
  width: 1px;
  height: auto;
  align-self: stretch;
}
.enterprise-ip-camera-frame113 {
  gap: 12px;
  width: 360px;
  height: 376px;
  display: flex;
  padding: 40px 0;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.enterprise-ip-camera-text49 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.enterprise-ip-camera-frame1142 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.enterprise-ip-camera-text51 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.enterprise-ip-camera-frame1062 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.20000000298023224);
  border-style: solid;
  border-width: 1px 0 0;
  flex-direction: column;
  background-color: var(--dl-color-orange-800main);
}
.enterprise-ip-camera-frame1042 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.enterprise-ip-camera-frame108 {
  width: 1280px;
  display: flex;
  padding: 20px 0;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.enterprise-ip-camera-text53 {
  color: var(--dl-color-white-white);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.enterprise-ip-camera-frame107 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.enterprise-ip-camera-uillinkedinalt {
  width: 20px;
  height: 20px;
}
.enterprise-ip-camera-facebook {
  width: 20px;
  height: 20px;
}
.enterprise-ip-camera-instagram {
  width: 20px;
  height: 20px;
}
@media(max-width: 1274px) {
  .enterprise-ip-camera-frame19272 {
    width: 100%;
    padding-top: 200px;
  }
  .enterprise-ip-camera-frame19268 {
    width: 768px;
  }
  .enterprise-ip-camera-frame19238 {
    flex-direction: column;
  }
  .enterprise-ip-camera-frame105 {
    width: 769px;
    height: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .enterprise-ip-camera-frame1041 {
    width: 769px;
    align-self: center;
    justify-content: center;
  }
  .enterprise-ip-camera-frame1061 {
    gap: 16px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .enterprise-ip-camera-frame111 {
    gap: 24px;
    width: auto;
    align-self: flex-start;
    padding-top: 24px;
    padding-left: 16px;
    padding-bottom: 24px;
  }
  .enterprise-ip-camera-frame109 {
    width: auto;
    margin-right: 0px;
  }
  .enterprise-ip-camera-frame110 {
    width: 277px;
    height: 419px;
    padding: 24px;
  }
  .enterprise-ip-camera-frame113 {
    width: 218px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .enterprise-ip-camera-frame1142 {
    width: 100%;
  }
  .enterprise-ip-camera-text51 {
    width: 100%;
    font-size: 24px;
  }
  .enterprise-ip-camera-frame1062 {
    width: 768px;
    align-self: center;
  }
  .enterprise-ip-camera-frame108 {
    width: 100%;
    align-self: center;
  }
}
@media(max-width: 768px) {
  .enterprise-ip-camera-frame19272 {
    padding-top: 120px;
    padding-bottom: 80px;
  }
  .enterprise-ip-camera-frame19268 {
    gap: 40px;
    width: 390px;
  }
  .enterprise-ip-camera-frame19260 {
    width: 100%;
  }
  .enterprise-ip-camera-frame19238 {
    height: auto;
  }
  .enterprise-ip-camera-frame61 {
    gap: 16px;
  }
  .enterprise-ip-camera-frame62 {
    gap: 16px;
  }
  .enterprise-ip-camera-frame63 {
    gap: 16px;
  }
  .enterprise-ip-camera-desktop-menu {
    display: none;
  }
  .enterprise-ip-camera-burger-menu {
    display: flex;
  }
  .enterprise-ip-camera-frame105 {
    width: 100%;
  }
  .enterprise-ip-camera-frame1041 {
    width: 100%;
  }
  .enterprise-ip-camera-frame1061 {
    gap: 16px;
    width: 390PX;
    flex-direction: column;
  }
  .enterprise-ip-camera-frame111 {
    width: 363px;
    align-self: center;
    margin-right: 0px;
  }
  .enterprise-ip-camera-text40 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 21px;
    text-decoration: none;
  }
  .enterprise-ip-camera-frame113 {
    height: auto;
    align-self: center;
  }
  .enterprise-ip-camera-frame1062 {
    width: 100%;
    height: 97px;
  }
  .enterprise-ip-camera-frame1042 {
    width: 100%;
    align-self: center;
  }
  .enterprise-ip-camera-frame108 {
    gap: 24px;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
  }
  .enterprise-ip-camera-text53 {
    color: var(--dl-color-white-white);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 21px;
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .enterprise-ip-camera-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .enterprise-ip-camera-desktop-menu {
    display: none;
  }
  .enterprise-ip-camera-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .enterprise-ip-camera-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .enterprise-ip-camera-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
