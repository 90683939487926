.smartcard-personalisation-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.smartcard-personalisation-navbar {
  width: 100%;
  height: 88px;
  display: flex;
  z-index: 100;
  position: fixed;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  justify-content: center;
  background-color: white;
}
.smartcard-personalisation-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.smartcard-personalisation-image1 {
  height: 3rem;
}
.smartcard-personalisation-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.smartcard-personalisation-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.smartcard-personalisation-link11 {
  text-decoration: none;
}
.smartcard-personalisation-burger-menu {
  display: none;
}
.smartcard-personalisation-icon1 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.smartcard-personalisation-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.smartcard-personalisation-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.smartcard-personalisation-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.smartcard-personalisation-logo {
  height: 3rem;
}
.smartcard-personalisation-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.smartcard-personalisation-icon3 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.smartcard-personalisation-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.smartcard-personalisation-link12 {
  text-decoration: none;
}
.smartcard-personalisation-main-content {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/desktop%20-%2020-1500w.png");
}
.smartcard-personalisation-frame19272 {
  gap: 10px;
  width: 1280px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  padding-top: 162px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 120px;
}
.smartcard-personalisation-frame19268 {
  gap: 80px;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 2;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.smartcard-personalisation-frame19310 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.smartcard-personalisation-navlink {
  display: contents;
}
.smartcard-personalisation-frame19229 {
  gap: 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.smartcard-personalisation-arrowleft {
  width: 24px;
  height: 24px;
}
.smartcard-personalisation-text10 {
  color: var(--dl-color-orange-800main);
  height: auto;
  text-align: left;
  line-height: 27px;
}
.smartcard-personalisation-frame19260 {
  gap: 24px;
  width: 730px;
  height: 300px;
  display: flex;
  padding: 32px 32px 48px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-orange-800main);
}
.smartcard-personalisation-frame19285 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
.smartcard-personalisation-text12 {
  color: var(--dl-color-white-white);
  height: auto;
  text-align: left;
  line-height: 24px;
}
.smartcard-personalisation-cardedit1 {
  width: 52px;
  height: 52px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.smartcard-personalisation-vuesaxlinearcardedit {
  top: 0px;
  left: 0px;
  width: 52px;
  height: 52px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-cardedit2 {
  top: 0px;
  left: 0px;
  width: 52px;
  height: 52px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-vector10 {
  top: 18.41666603088379px;
  left: 4.324940204620361px;
  width: 21px;
  height: 1px;
  position: absolute;
}
.smartcard-personalisation-vector11 {
  top: 35.75px;
  left: 12.991606712341309px;
  width: 4px;
  height: 1px;
  position: absolute;
}
.smartcard-personalisation-vector12 {
  top: 35.75px;
  left: 22.741607666015625px;
  width: 9px;
  height: 1px;
  position: absolute;
}
.smartcard-personalisation-vector13 {
  top: 7.583333492279053px;
  left: 4.324940204620361px;
  width: 43px;
  height: 37px;
  position: absolute;
}
.smartcard-personalisation-vector14 {
  top: 7.6567277908325195px;
  left: 32.16941452026367px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.smartcard-personalisation-vector15 {
  top: 10.09666633605957px;
  left: 40.18327331542969px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.smartcard-personalisation-text14 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.smartcard-personalisation-frame19286 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.smartcard-personalisation-text16 {
  color: var(--dl-color-neutral-500sub);
  width: 800px;
  height: auto;
  text-align: left;
  line-height: 27px;
}
.smartcard-personalisation-process {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: var(--dl-color-neutral-900main);
}
.smartcard-personalisation-frame19290 {
  gap: 64px;
  width: 1280px;
  display: flex;
  padding: 100px 0 160px;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.smartcard-personalisation-text18 {
  color: var(--dl-color-orange-800main);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.smartcard-personalisation-frame192381 {
  gap: 40px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.smartcard-personalisation-frame61 {
  gap: 40px;
  display: flex;
  padding: 32px 24px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-neutral-800);
}
.smartcard-personalisation-personalcard1 {
  width: 44px;
  height: 44px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.smartcard-personalisation-vuesaxlinearpersonalcard {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-personalcard2 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-vector16 {
  top: 5.5px;
  left: 3.6666667461395264px;
  width: 37px;
  height: 33px;
  position: absolute;
}
.smartcard-personalisation-vector17 {
  top: 14.666666984558105px;
  left: 25.66666603088379px;
  width: 9px;
  height: 1px;
  position: absolute;
}
.smartcard-personalisation-vector18 {
  top: 22px;
  left: 27.5px;
  width: 7px;
  height: 1px;
  position: absolute;
}
.smartcard-personalisation-vector19 {
  top: 29.33333396911621px;
  left: 31.16666603088379px;
  width: 4px;
  height: 1px;
  position: absolute;
}
.smartcard-personalisation-vector20 {
  top: 14.061666488647461px;
  left: 12.265000343322754px;
  width: 7px;
  height: 7px;
  position: absolute;
}
.smartcard-personalisation-vector21 {
  top: 24.882915496826172px;
  left: 9.166666984558105px;
  width: 13px;
  height: 5px;
  position: absolute;
}
.smartcard-personalisation-frame781 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smartcard-personalisation-text20 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.smartcard-personalisation-text22 {
  color: var(--dl-color-neutral-200);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.smartcard-personalisation-frame62 {
  gap: 40px;
  display: flex;
  padding: 32px 24px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-neutral-800);
}
.smartcard-personalisation-lock1 {
  width: 44px;
  height: 44px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.smartcard-personalisation-vuesaxlinearlock {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-lock2 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-vector22 {
  top: 3.6666667461395264px;
  left: 11px;
  width: 22px;
  height: 15px;
  position: absolute;
}
.smartcard-personalisation-vector23 {
  top: 18.33333396911621px;
  left: 3.6666667461395264px;
  width: 37px;
  height: 22px;
  position: absolute;
}
.smartcard-personalisation-vector24 {
  top: 28.41666603088379px;
  left: 28.42024803161621px;
  width: 2px;
  height: 2px;
  position: absolute;
}
.smartcard-personalisation-vector25 {
  top: 28.41666603088379px;
  left: 21.08512306213379px;
  width: 2px;
  height: 2px;
  position: absolute;
}
.smartcard-personalisation-vector26 {
  top: 28.41666603088379px;
  left: 13.75px;
  width: 2px;
  height: 2px;
  position: absolute;
}
.smartcard-personalisation-frame782 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smartcard-personalisation-text24 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.smartcard-personalisation-text26 {
  color: var(--dl-color-neutral-200);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.smartcard-personalisation-frame63 {
  gap: 40px;
  display: flex;
  padding: 32px 24px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-neutral-800);
}
.smartcard-personalisation-printer1 {
  width: 44px;
  height: 44px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.smartcard-personalisation-vuesaxlinearprinter {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-printer2 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-vector27 {
  top: 3.6666667461395264px;
  left: 13.291666984558105px;
  width: 17px;
  height: 9px;
  position: absolute;
}
.smartcard-personalisation-vector28 {
  top: 27.5px;
  left: 14.666666984558105px;
  width: 15px;
  height: 13px;
  position: absolute;
}
.smartcard-personalisation-vector29 {
  top: 12.833333015441895px;
  left: 5.5px;
  width: 33px;
  height: 20px;
  position: absolute;
}
.smartcard-personalisation-vector30 {
  top: 27.5px;
  left: 12.833333015441895px;
  width: 18px;
  height: 1px;
  position: absolute;
}
.smartcard-personalisation-vector31 {
  top: 20.16666603088379px;
  left: 12.833333015441895px;
  width: 6px;
  height: 1px;
  position: absolute;
}
.smartcard-personalisation-frame783 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smartcard-personalisation-text28 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.smartcard-personalisation-text30 {
  color: var(--dl-color-neutral-200);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.smartcard-personalisation-frame64 {
  gap: 40px;
  display: flex;
  padding: 32px 24px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-neutral-800);
}
.smartcard-personalisation-shieldtick1 {
  width: 44px;
  height: 44px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.smartcard-personalisation-vuesaxlinearshieldtick {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-shieldtick2 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-vector32 {
  top: 3.662083387374878px;
  left: 6.251667022705078px;
  width: 32px;
  height: 37px;
  position: absolute;
}
.smartcard-personalisation-vector33 {
  top: 16.829999923706055px;
  left: 16.59166717529297px;
  width: 11px;
  height: 8px;
  position: absolute;
}
.smartcard-personalisation-frame784 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smartcard-personalisation-text32 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.smartcard-personalisation-text34 {
  color: var(--dl-color-neutral-200);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.smartcard-personalisation-conclusion {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/conclustion%20bg-1500w.png");
}
.smartcard-personalisation-frame192382 {
  gap: 40px;
  width: 1280px;
  height: auto;
  display: flex;
  padding: 120px 0;
  z-index: 1;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
}
.smartcard-personalisation-frame19240 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smartcard-personalisation-text36 {
  color: var(--dl-color-blue-700main);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.smartcard-personalisation-text38 {
  color: var(--dl-color-neutral-500sub);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 27px;
}
.smartcard-personalisation-rectangle7424 {
  top: 0px;
  right: -2px;
  width: 100%;
  height: 460px;
  position: absolute;
}
.smartcard-personalisation-footer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: #EE7127;
}
.smartcard-personalisation-frame105 {
  gap: 0px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.20000000298023224);
  border-style: solid;
  border-width: 1px 0 0;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-orange-800main);
}
.smartcard-personalisation-frame1041 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.smartcard-personalisation-frame1061 {
  gap: 40px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.smartcard-personalisation-frame111 {
  gap: 100px;
  width: 398px;
  display: flex;
  padding: 40px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
}
.smartcard-personalisation-primarylogoasaplainbackground2 {
  width: 52px;
  height: 52px;
}
.smartcard-personalisation-frame109 {
  gap: 12px;
  width: 246px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.smartcard-personalisation-text40 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.smartcard-personalisation-text42 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.smartcard-personalisation-text44 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.smartcard-personalisation-text46 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.smartcard-personalisation-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.smartcard-personalisation-rectangle11 {
  width: 1px;
  height: auto;
  align-self: stretch;
}
.smartcard-personalisation-frame110 {
  gap: 12px;
  width: 360px;
  height: 376px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;
  padding-bottom: 24px;
}
.smartcard-personalisation-text48 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smartcard-personalisation-frame116 {
  gap: 24px;
  width: 100%;
  height: 263px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smartcard-personalisation-frame1141 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smartcard-personalisation-text50 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.smartcard-personalisation-text52 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smartcard-personalisation-frame1171 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.smartcard-personalisation-vuesaxlinearcall1 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.smartcard-personalisation-vuesaxlinearcall2 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-call1 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-call2 {
  top: 1.3333333730697632px;
  left: 1.3333333730697632px;
  width: 13.313333511352539px;
  height: 13.333333969116211px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-vector34 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 13px;
  position: absolute;
}
.smartcard-personalisation-text53 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smartcard-personalisation-rectangle13 {
  width: auto;
  height: 1px;
  align-self: stretch;
}
.smartcard-personalisation-frame115 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smartcard-personalisation-text55 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.smartcard-personalisation-text57 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smartcard-personalisation-frame1172 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.smartcard-personalisation-vuesaxlinearcall3 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.smartcard-personalisation-vuesaxlinearcall4 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-call3 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-call4 {
  top: 1.3333333730697632px;
  left: 1.3333333730697632px;
  width: 13.313333511352539px;
  height: 13.333333969116211px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.smartcard-personalisation-vector35 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 13px;
  position: absolute;
}
.smartcard-personalisation-text59 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smartcard-personalisation-rectangle12 {
  width: 1px;
  height: auto;
  align-self: stretch;
}
.smartcard-personalisation-frame113 {
  gap: 12px;
  width: 360px;
  height: 376px;
  display: flex;
  padding: 40px 0;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.smartcard-personalisation-text61 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smartcard-personalisation-frame1142 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smartcard-personalisation-text63 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.smartcard-personalisation-frame1062 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.20000000298023224);
  border-style: solid;
  border-width: 1px 0 0;
  flex-direction: column;
  background-color: var(--dl-color-orange-800main);
}
.smartcard-personalisation-frame1042 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.smartcard-personalisation-frame108 {
  width: 1280px;
  display: flex;
  padding: 20px 0;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.smartcard-personalisation-text65 {
  color: var(--dl-color-white-white);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.smartcard-personalisation-frame107 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.smartcard-personalisation-uillinkedinalt {
  width: 20px;
  height: 20px;
}
.smartcard-personalisation-facebook {
  width: 20px;
  height: 20px;
}
.smartcard-personalisation-instagram {
  width: 20px;
  height: 20px;
}
@media(max-width: 1274px) {
  .smartcard-personalisation-frame19272 {
    width: 100%;
    padding-top: 200px;
  }
  .smartcard-personalisation-frame19268 {
    width: 768px;
  }
  .smartcard-personalisation-frame19310 {
    width: 100%;
  }
  .smartcard-personalisation-frame19260 {
    width: 100%;
  }
  .smartcard-personalisation-frame19290 {
    width: 768px;
  }
  .smartcard-personalisation-frame192381 {
    flex-direction: column;
  }
  .smartcard-personalisation-frame192382 {
    width: 768px;
    align-self: center;
  }
  .smartcard-personalisation-frame105 {
    width: 769px;
    height: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .smartcard-personalisation-frame1041 {
    width: 769px;
    align-self: center;
    justify-content: center;
  }
  .smartcard-personalisation-frame1061 {
    gap: 16px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .smartcard-personalisation-frame111 {
    gap: 24px;
    width: auto;
    align-self: flex-start;
    padding-top: 24px;
    padding-left: 16px;
    padding-bottom: 24px;
  }
  .smartcard-personalisation-frame109 {
    width: auto;
    margin-right: 0px;
  }
  .smartcard-personalisation-frame110 {
    width: 277px;
    height: 419px;
    padding: 24px;
  }
  .smartcard-personalisation-frame113 {
    width: 218px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .smartcard-personalisation-frame1142 {
    width: 100%;
  }
  .smartcard-personalisation-text63 {
    width: 100%;
    font-size: 24px;
  }
  .smartcard-personalisation-frame1062 {
    width: 768px;
    align-self: center;
  }
  .smartcard-personalisation-frame108 {
    width: 100%;
    align-self: center;
  }
}
@media(max-width: 768px) {
  .smartcard-personalisation-desktop-menu {
    display: none;
  }
  .smartcard-personalisation-burger-menu {
    display: flex;
  }
  .smartcard-personalisation-frame19268 {
    width: 390px;
  }
  .smartcard-personalisation-frame19290 {
    width: 390px;
  }
  .smartcard-personalisation-frame192382 {
    width: 390px;
  }
  .smartcard-personalisation-frame19240 {
    width: 390ox;
  }
  .smartcard-personalisation-frame105 {
    width: 100%;
  }
  .smartcard-personalisation-frame1041 {
    width: 100%;
  }
  .smartcard-personalisation-frame1061 {
    gap: 16px;
    width: 390PX;
    flex-direction: column;
  }
  .smartcard-personalisation-frame111 {
    width: 363px;
    align-self: center;
    margin-right: 0px;
  }
  .smartcard-personalisation-text52 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 21px;
    text-decoration: none;
  }
  .smartcard-personalisation-frame113 {
    height: auto;
    align-self: center;
  }
  .smartcard-personalisation-frame1062 {
    width: 100%;
    height: 97px;
  }
  .smartcard-personalisation-frame1042 {
    width: 100%;
    align-self: center;
  }
  .smartcard-personalisation-frame108 {
    gap: 24px;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
  }
  .smartcard-personalisation-text65 {
    color: var(--dl-color-white-white);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 21px;
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .smartcard-personalisation-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .smartcard-personalisation-desktop-menu {
    display: none;
  }
  .smartcard-personalisation-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .smartcard-personalisation-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .smartcard-personalisation-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
