.prepaid-payment-system-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.prepaid-payment-system-navbar {
  width: 100%;
  height: 88px;
  display: flex;
  z-index: 100;
  position: fixed;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  justify-content: center;
  background-color: white;
}
.prepaid-payment-system-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.prepaid-payment-system-image1 {
  height: 3rem;
}
.prepaid-payment-system-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.prepaid-payment-system-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.prepaid-payment-system-link11 {
  text-decoration: none;
}
.prepaid-payment-system-burger-menu {
  display: none;
}
.prepaid-payment-system-icon1 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.prepaid-payment-system-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.prepaid-payment-system-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prepaid-payment-system-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.prepaid-payment-system-logo {
  height: 3rem;
}
.prepaid-payment-system-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prepaid-payment-system-icon3 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.prepaid-payment-system-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.prepaid-payment-system-link12 {
  text-decoration: none;
}
.prepaid-payment-system-main-content {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/desktop%20-%2020-1500w.png");
}
.prepaid-payment-system-frame19272 {
  gap: 10px;
  width: 1440px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  padding-top: 162px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 120px;
}
.prepaid-payment-system-frame19268 {
  gap: 80px;
  width: 1280px;
  display: flex;
  z-index: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.prepaid-payment-system-frame19308 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prepaid-payment-system-navlink {
  display: contents;
}
.prepaid-payment-system-frame19229 {
  gap: 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.prepaid-payment-system-arrowleft {
  width: 24px;
  height: 24px;
}
.prepaid-payment-system-text10 {
  color: var(--dl-color-orange-800main);
  height: auto;
  text-align: left;
  line-height: 27px;
}
.prepaid-payment-system-frame19260 {
  gap: 24px;
  width: 730px;
  height: 300px;
  display: flex;
  padding: 32px 32px 48px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-orange-800main);
}
.prepaid-payment-system-frame19285 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
.prepaid-payment-system-text12 {
  color: var(--dl-color-white-white);
  height: auto;
  text-align: left;
  line-height: 24px;
}
.prepaid-payment-system-cardreceive1 {
  width: 52px;
  height: 52px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.prepaid-payment-system-vuesaxlinearcardreceive {
  top: 0px;
  left: 0px;
  width: 52px;
  height: 52px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.prepaid-payment-system-cardreceive2 {
  top: 0px;
  left: 0px;
  width: 52px;
  height: 52px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.prepaid-payment-system-vector10 {
  top: 18.41666603088379px;
  left: 4.333333492279053px;
  width: 27px;
  height: 1px;
  position: absolute;
}
.prepaid-payment-system-vector11 {
  top: 35.75px;
  left: 13px;
  width: 4px;
  height: 1px;
  position: absolute;
}
.prepaid-payment-system-vector12 {
  top: 35.75px;
  left: 22.75px;
  width: 9px;
  height: 1px;
  position: absolute;
}
.prepaid-payment-system-vector13 {
  top: 7.583333492279053px;
  left: 4.333333492279053px;
  width: 43px;
  height: 37px;
  position: absolute;
}
.prepaid-payment-system-vector14 {
  top: 7.583333492279053px;
  left: 43.33333206176758px;
  width: 4px;
  height: 13px;
  position: absolute;
}
.prepaid-payment-system-vector15 {
  top: 16.25px;
  left: 39px;
  width: 4px;
  height: 4px;
  position: absolute;
}
.prepaid-payment-system-text14 {
  color: var(--dl-color-white-white);
  height: auto;
  font-size: 48px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: Archivo Narrow;
  font-weight: 600px;
  line-height: normal;
  font-stretch: normal;
  letter-spacing: 0em;
}
.prepaid-payment-system-frame19289 {
  gap: 40px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prepaid-payment-system-frame19286 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.prepaid-payment-system-text16 {
  color: var(--dl-color-neutral-500sub);
  width: 800px;
  height: auto;
  text-align: left;
  line-height: 27px;
}
.prepaid-payment-system-frame19288 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: flex-end;
}
.prepaid-payment-system-text18 {
  color: var(--dl-color-neutral-500sub);
  width: 800px;
  height: auto;
  font-size: 18px;
  font-style: normal;
  text-align: left;
  font-family: Switzer;
  font-weight: 500px;
  line-height: 27px;
  font-stretch: normal;
  letter-spacing: 0em;
}
.prepaid-payment-system-advantages {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: var(--dl-color-neutral-900main);
}
.prepaid-payment-system-frame19290 {
  gap: 64px;
  width: 1280px;
  display: flex;
  padding: 100px 0 160px;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.prepaid-payment-system-text20 {
  color: var(--dl-color-orange-800main);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.prepaid-payment-system-frame192381 {
  gap: 40px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
}
.prepaid-payment-system-frame61 {
  gap: 40px;
  width: 400px;
  display: flex;
  padding: 32px 24px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-neutral-800);
}
.prepaid-payment-system-cardpos1 {
  width: 44px;
  height: 44px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.prepaid-payment-system-vuesaxlinearcardpos {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.prepaid-payment-system-cardpos2 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.prepaid-payment-system-vector16 {
  top: 7.203531742095947px;
  left: 7.204427242279053px;
  width: 22px;
  height: 22px;
  position: absolute;
}
.prepaid-payment-system-vector17 {
  top: 31.311500549316406px;
  left: 20.35232925415039px;
  width: 2px;
  height: 2px;
  position: absolute;
}
.prepaid-payment-system-vector18 {
  top: 24.19767189025879px;
  left: 25.287109375px;
  width: 4px;
  height: 4px;
  position: absolute;
}
.prepaid-payment-system-vector19 {
  top: 3.6693522930145264px;
  left: 3.6666667461395264px;
  width: 37px;
  height: 37px;
  position: absolute;
}
.prepaid-payment-system-vector20 {
  top: 40.33064651489258px;
  left: 3.6666667461395264px;
  width: 37px;
  height: 1px;
  position: absolute;
}
.prepaid-payment-system-frame781 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prepaid-payment-system-text22 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.prepaid-payment-system-text24 {
  color: var(--dl-color-neutral-200);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.prepaid-payment-system-frame62 {
  gap: 40px;
  width: 400px;
  display: flex;
  padding: 32px 24px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-neutral-800);
}
.prepaid-payment-system-receipttext {
  width: 44px;
  height: 44px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.prepaid-payment-system-vuesaxlinearreceipttext {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.prepaid-payment-system-vector21 {
  top: 3.6666667461395264px;
  left: 29.33333396911621px;
  width: 11px;
  height: 16px;
  position: absolute;
}
.prepaid-payment-system-vector22 {
  top: 3.6666667461395264px;
  left: 3.6666667461395264px;
  width: 29px;
  height: 38px;
  position: absolute;
}
.prepaid-payment-system-vector23 {
  top: 16.5px;
  left: 11px;
  width: 11px;
  height: 1px;
  position: absolute;
}
.prepaid-payment-system-vector24 {
  top: 23.83333396911621px;
  left: 12.375px;
  width: 8px;
  height: 1px;
  position: absolute;
}
.prepaid-payment-system-frame782 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prepaid-payment-system-text26 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.prepaid-payment-system-text28 {
  color: var(--dl-color-neutral-200);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.prepaid-payment-system-frame63 {
  gap: 40px;
  width: 400px;
  display: flex;
  padding: 32px 24px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-neutral-800);
}
.prepaid-payment-system-shieldtick1 {
  width: 44px;
  height: 44px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.prepaid-payment-system-vuesaxlinearshieldtick {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.prepaid-payment-system-shieldtick2 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.prepaid-payment-system-vector25 {
  top: 3.662083387374878px;
  left: 6.251667022705078px;
  width: 32px;
  height: 37px;
  position: absolute;
}
.prepaid-payment-system-vector26 {
  top: 16.829999923706055px;
  left: 16.59166717529297px;
  width: 11px;
  height: 8px;
  position: absolute;
}
.prepaid-payment-system-frame783 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prepaid-payment-system-text30 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.prepaid-payment-system-text32 {
  color: var(--dl-color-neutral-200);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.prepaid-payment-system-conclusion {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/conclustion%20bg-1500w.png");
}
.prepaid-payment-system-frame192382 {
  gap: 40px;
  width: 1280px;
  display: flex;
  padding: 120px 0;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
}
.prepaid-payment-system-frame19240 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prepaid-payment-system-text34 {
  color: var(--dl-color-blue-700main);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.prepaid-payment-system-text36 {
  color: var(--dl-color-neutral-500sub);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 27px;
}
.prepaid-payment-system-rectangle7424 {
  top: 0px;
  right: -2px;
  width: 100%;
  height: 460px;
  position: absolute;
}
.prepaid-payment-system-footer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: #EE7127;
}
.prepaid-payment-system-frame105 {
  gap: 0px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.20000000298023224);
  border-style: solid;
  border-width: 1px 0 0;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-orange-800main);
}
.prepaid-payment-system-frame1041 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prepaid-payment-system-frame1061 {
  gap: 40px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.prepaid-payment-system-frame111 {
  gap: 100px;
  width: 398px;
  display: flex;
  padding: 40px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
}
.prepaid-payment-system-primarylogoasaplainbackground2 {
  width: 52px;
  height: 52px;
}
.prepaid-payment-system-frame109 {
  gap: 12px;
  width: 246px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.prepaid-payment-system-text38 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.prepaid-payment-system-text40 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.prepaid-payment-system-text42 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.prepaid-payment-system-text44 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.prepaid-payment-system-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prepaid-payment-system-rectangle11 {
  width: 1px;
  height: auto;
  align-self: stretch;
}
.prepaid-payment-system-frame110 {
  gap: 12px;
  width: 360px;
  height: 376px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;
  padding-bottom: 24px;
}
.prepaid-payment-system-text46 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.prepaid-payment-system-frame116 {
  gap: 24px;
  width: 100%;
  height: 263px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prepaid-payment-system-frame1141 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prepaid-payment-system-text48 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.prepaid-payment-system-text50 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.prepaid-payment-system-frame1171 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.prepaid-payment-system-vuesaxlinearcall1 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.prepaid-payment-system-vuesaxlinearcall2 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.prepaid-payment-system-call1 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.prepaid-payment-system-call2 {
  top: 1.3333333730697632px;
  left: 1.3333333730697632px;
  width: 13.313333511352539px;
  height: 13.333333969116211px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.prepaid-payment-system-vector27 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 13px;
  position: absolute;
}
.prepaid-payment-system-text51 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.prepaid-payment-system-rectangle13 {
  width: auto;
  height: 1px;
  align-self: stretch;
}
.prepaid-payment-system-frame115 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prepaid-payment-system-text53 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.prepaid-payment-system-text55 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.prepaid-payment-system-frame1172 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.prepaid-payment-system-vuesaxlinearcall3 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.prepaid-payment-system-vuesaxlinearcall4 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.prepaid-payment-system-call3 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.prepaid-payment-system-call4 {
  top: 1.3333333730697632px;
  left: 1.3333333730697632px;
  width: 13.313333511352539px;
  height: 13.333333969116211px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.prepaid-payment-system-vector28 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 13px;
  position: absolute;
}
.prepaid-payment-system-text57 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.prepaid-payment-system-rectangle12 {
  width: 1px;
  height: auto;
  align-self: stretch;
}
.prepaid-payment-system-frame113 {
  gap: 12px;
  width: 360px;
  height: 376px;
  display: flex;
  padding: 40px 0;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.prepaid-payment-system-text59 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.prepaid-payment-system-frame1142 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prepaid-payment-system-text61 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
.prepaid-payment-system-frame1062 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.20000000298023224);
  border-style: solid;
  border-width: 1px 0 0;
  flex-direction: column;
  background-color: var(--dl-color-orange-800main);
}
.prepaid-payment-system-frame1042 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prepaid-payment-system-frame108 {
  width: 1280px;
  display: flex;
  padding: 20px 0;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.prepaid-payment-system-text63 {
  color: var(--dl-color-white-white);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
  font-stretch: normal;
  text-decoration: none;
}
.prepaid-payment-system-frame107 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.prepaid-payment-system-uillinkedinalt {
  width: 20px;
  height: 20px;
}
.prepaid-payment-system-facebook {
  width: 20px;
  height: 20px;
}
.prepaid-payment-system-instagram {
  width: 20px;
  height: 20px;
}
@media(max-width: 1274px) {
  .prepaid-payment-system-frame19272 {
    width: 100%;
    padding-top: 200px;
  }
  .prepaid-payment-system-frame19268 {
    gap: 40px;
    width: 769px;
  }
  .prepaid-payment-system-frame19308 {
    width: 100%;
  }
  .prepaid-payment-system-frame19260 {
    width: 100%;
  }
  .prepaid-payment-system-frame19290 {
    width: 769px;
    padding-top: 40px;
    padding-bottom: 80px;
  }
  .prepaid-payment-system-text20 {
    align-self: center;
  }
  .prepaid-payment-system-frame192381 {
    flex-direction: column;
  }
  .prepaid-payment-system-frame192382 {
    width: 768px;
  }
  .prepaid-payment-system-frame105 {
    width: 769px;
    height: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .prepaid-payment-system-frame1041 {
    width: 769px;
    align-self: center;
    justify-content: center;
  }
  .prepaid-payment-system-frame1061 {
    gap: 16px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .prepaid-payment-system-frame111 {
    gap: 24px;
    width: auto;
    align-self: flex-start;
    padding-top: 24px;
    padding-left: 16px;
    padding-bottom: 24px;
  }
  .prepaid-payment-system-frame109 {
    width: auto;
    margin-right: 0px;
  }
  .prepaid-payment-system-frame110 {
    width: 277px;
    height: 419px;
    padding: 24px;
  }
  .prepaid-payment-system-frame113 {
    width: 218px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .prepaid-payment-system-frame1142 {
    width: 100%;
  }
  .prepaid-payment-system-text61 {
    width: 100%;
    font-size: 24px;
  }
  .prepaid-payment-system-frame1062 {
    width: 768px;
    align-self: center;
  }
  .prepaid-payment-system-frame108 {
    width: 100%;
    align-self: center;
  }
}
@media(max-width: 768px) {
  .prepaid-payment-system-desktop-menu {
    display: none;
  }
  .prepaid-payment-system-burger-menu {
    display: flex;
  }
  .prepaid-payment-system-frame19268 {
    gap: 40px;
    width: 390px;
  }
  .prepaid-payment-system-frame19308 {
    width: 100%;
  }
  .prepaid-payment-system-frame19260 {
    width: 100%;
  }
  .prepaid-payment-system-text14 {
    font-size: 32px;
  }
  .prepaid-payment-system-frame19290 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .prepaid-payment-system-frame192382 {
    width: 390px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .prepaid-payment-system-frame105 {
    width: 100%;
  }
  .prepaid-payment-system-frame1041 {
    width: 100%;
  }
  .prepaid-payment-system-frame1061 {
    gap: 16px;
    width: 390PX;
    flex-direction: column;
  }
  .prepaid-payment-system-frame111 {
    width: 363px;
    align-self: center;
    margin-right: 0px;
  }
  .prepaid-payment-system-text50 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 21px;
    text-decoration: none;
  }
  .prepaid-payment-system-frame113 {
    height: auto;
    align-self: center;
  }
  .prepaid-payment-system-frame1062 {
    width: 100%;
    height: 97px;
  }
  .prepaid-payment-system-frame1042 {
    width: 100%;
    align-self: center;
  }
  .prepaid-payment-system-frame108 {
    gap: 24px;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
  }
  .prepaid-payment-system-text63 {
    color: var(--dl-color-white-white);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 21px;
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .prepaid-payment-system-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .prepaid-payment-system-desktop-menu {
    display: none;
  }
  .prepaid-payment-system-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .prepaid-payment-system-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .prepaid-payment-system-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
